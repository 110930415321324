<template>
  <swiper :loop="true" :slides-per-view="1" @click-slide="slideClickHandler" class="swiper" :options="swiperOptions">
    <swiper-slide @click-slide="slideClickHandler(slide.link)" class="swiper-slide" v-for="slide, index in slidesList" :key="index">
      <img :src="`/banner/${$i18n.locale}_${slide.image}`" alt="">
    </swiper-slide>
    <div style="color: white;" class="swiper-button-prev" slot="button-prev"></div>
    <div style="color: white;" class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import "swiper/css/swiper.css";

export default {
  name: "IndexSlider",
  props: {
    slidesList: Array
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 15,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    slideClickHandler(slide) {
      this.$router.push(this.$linkCreator(this.$i18n.locale, this.slidesList[slide].link));
    }
  }
}
</script>

<style lang="scss" scoped>

.swiper {
  max-width: 925px;

  .swiper-slide {
    border-radius: 2px;
    height: 146px;
    width: 288px !important;
    display: flex;
    align-items: center;
    background-color: #272727;
    color: white;
    border: 2px solid #272727;
    transition: .3s;
    cursor: pointer;

    &:hover {
      border-color: #7A77C5;
    }

    img {
      border-radius: 2px;
    }
  }
}

</style>